<template>
<div>
<v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app class="nav-bar card-title" style="" dark>
    <v-app-bar-nav-icon @click.stop="toggleDrawer" />
    <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">Library Super Admin</span>
    </v-toolbar-title><v-spacer />
    <span v-if="org!=null">
        <center>
            <h3>
                <v-chip class="ma-2 pa-4" color="orange" text-color="white" >
                    <v-avatar left>
                        <v-icon size="22">home_work</v-icon>
                    </v-avatar>
                    {{org}}
                </v-chip>
            </h3>
        </center>
    </span>
    <v-spacer />
    <!-- <v-btn icon>
       <v-avatar >
      <v-icon>mdi-bell</v-icon>
      </v-avatar> 
    </v-btn>-->
    <div style="width:10px"> </div>
    <div class="text-center">
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x transition="scroll-y-transition">
            <template v-slot:activator="{ on }">
                <span  v-if="!mobile">Hello Admin</span>
                <v-btn icon v-on="on">
                    <span v-if="profile_image_path">
                        <v-avatar>
                            <v-img :src="awspath+profile_image_path+profile_image_name"></v-img>
                        </v-avatar>
                    </span>
                    <span v-else>
                        <v-avatar color="white">
                            <span class="indigo--text headline">{{initials}}</span>
                        </v-avatar>
                    </span>
                </v-btn>
            </template>
            <v-card>
                <v-list>
                    <v-list-item>
                        <v-list-item-avatar>
                            <span v-if="profile_image_path">
                                <v-avatar>
                                    <v-img :src="awspath+profile_image_path+profile_image_name"></v-img>
                                </v-avatar>
                            </span>
                            <span v-else>
                                <v-avatar color="#4A148C">
                                    <span class="white--text headline">{{initials}}</span>
                                </v-avatar>
                            </span>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>Hello,<br /> <span style="padding-bottom:15px"></span>Super Admin</v-list-item-title>
                            <!-- <v-list-item-subtitle><span style="padding-bottom:5px"></span>{{uid}}</v-list-item-subtitle> -->
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list shaped>
                    <v-list-item-group color="primary">
                        <!-- <router-link to="/update-password" class="sidebar-link">
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon size="25" color="warning">mdi-account-key</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Change Password</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </router-link> -->
                    </v-list-item-group>
                </v-list>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="ma-1" outlined small fab color="error" @click="closeMenuAndLogout()" title="logout">
                        <v-icon>mdi-location-exit</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</v-app-bar>
 <v-navigation-drawer class="d-print-none" v-model="$store.state.drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
        <!-- {{search_links}} -->
        <div v-for="(child, i) in links" :key="i">
          {{child.name}}
            <router-link class="sidebar-link" :to="{ name: child.router_link}">
                <v-list-item class="sidebar-link-item">
                    <span>
                        <v-icon size="32" class="instagram">{{ child.icon }}</v-icon>
                    </span>
                    <v-list-item-content>
                        <v-list-item-title class="title-role" style="">{{ child.linkname }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <v-divider color="#90A4AE" style="margin:0px"></v-divider>
        </div>
    </v-navigation-drawer>
     <v-content>
      <v-container fluid> 
             <router-view></router-view> 
      </v-container>
      <!-- <div>Quick-Links RD
        <router-link :to="{name:'employee-type'}">click</router-link>
      </div> -->
    </v-content>
</div>
</template>

<script>
import axios from 'axios'

export default {
 
    data: () => ({
        initials: '',
        menu: false,
        org: null,
        username: '',
        uid: null,
        data: {},
        profile_image_name: '',
        profile_image_path: '',
        mobile:false,
       links:null
    }),
    methods: {
        toggleDrawer() {
            this.$store.state.drawer = !this.$store.state.drawer;
        },
        closeMenuAndLogout() {
            if (this.uid == "superadmin@easypariksha.com") {
                this.menu = false;
                localStorage.clear();
                this.$router.push('/eps-admin');
            } else {
                this.menu = false;
                localStorage.clear();
                this.$router.push('/superadmin');
            }

        },
        imageReplacement(uername) {
            //var names = this.uername.split('');
            //console.log("names "+names)
            this.initials = uername[0].charAt(0).toUpperCase()
            // window.alert(initials)
        }
    },
    mounted() {
          //alert("Data ::"+localStorage.getItem('uid'))
          this.uid=localStorage.getItem('uid')
          const data={
            uid:this.uid
          }
          axios
          .post("/SuperAdmin/getSuperAdminLink",data)
            .then(res => {
                this.links = res.data.links;
            })
            .catch(error => {
                window.console.log(error);
                this.$router.push("/");
            });
        let devicewidth = window.innerWidth
        if(devicewidth < 700){
            this.mobile = true
        }

        
    },
};
</script>

<style scoped>
.sidebar-link {
    text-decoration: none;
}
.card-title{
  background: rgb(13,12,12);
  background: linear-gradient(90deg, rgba(13,12,12,1) 0%, rgba(84,82,84,1) 50%, rgba(184,181,183,1) 98%); 
  color: #FFF;  
}
.sidebar-link-item:hover {
    background-color: rgb(181, 186, 190) !important;
}

.hidden-sm-and-down {
    font-weight: 500;
}

.nav-bar {
    /* background: cadetblue !important; */
    background: #000000c7;
}
.sidebar-link {
    text-decoration: none;
}

.sidebar-link-item:hover {
    background-color: rgb(202, 206, 209) !important;
}

.title-role {
    margin-left: 35px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    line-height: 1.0;
    font-weight: 500;
}

.instagram {
    background: -webkit-radial-gradient(33% 100% circle, #30CFD0 4%, #30CFD0 22%, #30CFD0 22%, #330867 85%, #330867);
    background: radial-gradient(circle at 33% 100%, #30CFD0 4%, #30CFD0 22%, #30CFD0 22%, #330867 85%, #330867);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>
